import Component from 'vue-class-component'
import Dashboard from '../Dashboard'
import { generateFilters } from './filters'
import { filterDates } from '../types'

@Component
export default class SupervisorDashboard extends Dashboard {
  beforeCreate () {
    this.filterGenerator = generateFilters
    this.specificFilterGenerator = (dates: filterDates, id: number) => {
      return {}
    }
  }
}
