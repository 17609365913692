
  import { channelColors } from '@/utils/channelColors'
  import { Component } from 'vue-property-decorator'
  import SupervisorDashboard from './SupervisorDashboard'

@Component
  export default class SupervisorLeads extends SupervisorDashboard {
    // Methods
    beforeCreate () {
      this.getData = async () => {
        this.$set(this.chartData.currentMonth, 'leads', await this.getLeads(this.currentMonthFilters.leads))
        this.$set(this.chartData.prevMonth, 'leads', await this.getLeads(this.prevMonthFilters.leads))
        this.$set(this.chartData.lastYear, 'leads', await this.getLeads(this.lastYearFilters.leads))
      }
    }

    colorChannels (leads) {
      for (let i = 0; i < leads.length; i++) {
        if (leads[i].channel) {
          leads[i].channel.color = channelColors[leads?.[i]?.channel?.name] || '#737373'
        } else {
          leads[i].channel = undefined
        }
      }
      return leads
    }

  // Getters

  // Watchers
  }
